@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&family=Righteous&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    line-height: 1em; }

h1 {
    font-family: 'Righteous', 'Poppins', sans-serif;
    font-weight: 300; }

h2 {
    font-weight: 200; }

p {
    font-weight: 200; }
