.container {
    height: 100vh;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100vw;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    scrollbar-width: none;

    @media (min-width: 450px) and (max-width: 1024px) {
        scroll-snap-type: y none; }

    &::-webkit-scrollbar {
        display: none; }

    header, section {
        min-height: 100vh;
        min-height: -webkit-fill-available;
        max-height: 100vh;
        max-height: -webkit-fill-available;
        width: 100%;
        scroll-snap-align: start;

        @media (min-width: 450px) and (max-width: 1024px) {
            scroll-snap-align: none; }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }

    header {
        background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%);
        align-items: center;
        text-align: center;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1;
            object-fit: cover; }

        div {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transform: translateY(-50%); }

        h1 {
            background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            font-size: 10em;
            font-variant: all-small-caps;

            @media (min-width: 450px) and (max-width: 1024px) {
                font-size: 20em; } }

        h2 {
            background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            font-size: 2.5em;

            @media (min-width: 450px) and (max-width: 1024px) {
                font-size: 5em; } } }

    section {
        background-color: whitesmoke;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            transform: rotate(180deg) rotateY(180deg);
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1;
            object-fit: cover;

            @media (min-width: 450px) and (max-width: 1024px) {
                display: none; } }

        .sectionend {
            height: 200px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @media (min-width: 450px) and (max-width: 1024px) {
                height: 150px; }

            &:first-child {
                height: 150px;
                h1 {
                    color: whitesmoke; }

                @media (min-width: 450px) and (max-width: 1024px) {
                        height: 150px; } }

            @media (min-width: 450px) and (max-width: 1024px) {
                &:first-child {
                    h1 {
                        color: #222; } } }

            button {
                height: 50px;
                width: 400px;
                border: none;
                outline: none;
                background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
                background-size: 150%;
                color: whitesmoke;
                font-size: 1.3em;
                -webkit-transition: .3s;
                -moz-transition: .3s;
                transition: .3s;

                @media (min-width: 450px) and (max-width: 1024px) {
                    width: 90%;
                    height: 100px;
                    font-size: 2.5em; }

                &:hover {
                    background-position-x: 100%;
                    -webkit-transition: .3s;
                    -moz-transition: .3s;
                    transition: .3s; }

                &:focus {
                    outline: none; } } }
        .sectioncentre {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            div {
                flex: 1;
                margin: 0;
                display: flex;
                flex-direction: column;
                justify-content: bottom;
                align-items: bottom;

                &:first-child {
                    h1 {
                        color: whitesmoke;

                        @media (min-width: 450px) and (max-width: 1024px) {
                            color: #555; } } }

                h1 {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center; }

                p {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center; } } }

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 2;

            h1 {
                color: #555;
                font-size: 5em;
                font-variant: all-small-caps;

                @media (min-width: 450px) and (max-width: 1024px) {
                    font-size: 10em; } }

            p {
                font-size: 1.5em;
                text-align: center;
                color: #222;

                @media (min-width: 450px) and (max-width: 1024px) {
                    font-size: 2.5em; } } } } }
