.container {
    min-height: 100vh;
    width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
        height: 100px;
        width: 100%;
        background: #111;
        color: whitesmoke;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (min-width: 450px) and (max-width: 1024px) {
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                height: 100px;
                width: 100%;
                font-size: 3em;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center; } } }


    .tracks {
        flex: 1;
        max-height: calc(100vh - 100px);
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none; }

        .trackContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            // gap: 1em

            .track {
                width: 300px;
                height: 300px;
                flex: 1 1 300px;
                position: relative;
                overflow: hidden;

                @media (min-width: 450px) and (max-width: 1024px) {
                    width: 500px;
                    height: 500px;
                    flex: 1 1 500px; }

                a {
                    text-decoration: none;
                    color: whitesmoke; }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;

                    filter: saturate(0.6) contrast(130%); }

                .cover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.9);

                    @media (min-width: 450px) and (max-width: 1024px) {
                        font-size: 3em; }

                    -webkit-transition: .3s;
                    -moz-transition: .3s;
                    transition: .3s;

                    &:hover {
                        opacity: 1;

                        -webkit-transition: .3s;
                        -moz-transition: .3s;
                        transition: .3s; } } } } } }


