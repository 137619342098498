.container {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100vw;
    max-width: 100vw;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .auth {
        min-height: 100%;
        width: 100vw;
        overflow: hidden;
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        .skipper {
            position: fixed;
            bottom: 10px;
            z-index: 100;
            width: 100%;
            height: 100px;
            padding: 20px;
            font-size: 2em;
            color: whitesmoke;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .button {
                width: 60px;
                height: 60px;
                border-radius: 222em;
                background: #222;
                box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                cursor: pointer;

                @media (min-width: 450px) and (max-width: 1024px) {
                    height: 100px;
                    width: 100px;
                    font-size: 2em; }

                &:hover {
                    background: desaturate(lighten(#222, 15%), 0%);
                    -webkit-transition: .3s;
                    -moz-transition: .3s;
                    transition: .3s; }

                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-transition: .3s;
                -moz-transition: .3s;
                transition: .3s; }

            p {
                width: 50px;
                height: 50px;
                border-radius: 222em;
                background: #222;
                box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                @media (min-width: 450px) and (max-width: 1024px) {
                    width: 90px;
                    height: 90px;
                    font-size: 2em; }

                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none; } }

        .page {
            height: auto;
            width: 100vw; } } }

