.container {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    @media (min-width: 450px) and (max-width: 1024px) {
        h2 {
            font-size: 4em !important; } }

    img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 1; }

    h2 {
        font-size: 2em;
        color: #222;
        margin: 20px;
        z-index: 2; }


    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;

        @media (min-width: 450px) and (max-width: 1024px) {
            width: 100%; }

        input, button {
            width: 300px;
            height: 50px;
            margin: 5px;
            border: none;
            outline: none;
            font-size: 1.1em;
            background: white;
            border: solid 2px rgba(0, 0, 0, 0.2);
            color: #222;

            @media (min-width: 450px) and (max-width: 1024px) {
                width: 90% !important;
                height: 100px;
                margin: 10px;
                font-size: 2em; }

            &:focus {
                outline: none; } }

        input {
            padding: 0 5px;

            @media (min-width: 450px) and (max-width: 1024px) {
                padding: 0 20px; } }

        button {
            background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
            background-size: 150%;
            color: whitesmoke;
            -webkit-transition: .3s;
            -moz-transition: .3s;
            transition: .3s;

            &:disabled {
                background-image: linear-gradient(15deg, darken(#13547a, 10%) 0%, darken(#80d0c7, 10%) 100%);
                background-size: 150%;
                -webkit-transition: .3s;
                -moz-transition: .3s;
                transition: .3s;

                &:hover {
                    background-position-x: 0%; } }

            &:hover {
                background-position-x: 100%;
                -webkit-transition: .3s;
                -moz-transition: .3s;
                transition: .3s; } } } }
